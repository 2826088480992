@import url('https://fonts.googleapis.com/css?family=Raleway:300, 400,700');

* {
  box-sizing: border-box;
}

$phone: 415px;
$tab: 768px;
$laptop: 1024px;
$desktop: 1280px;

$padding: 16px;
$color--background: #F1EAF4;
$color--border: gainsboro;
$color--theme: #8F54BA;
$color--white: #fff;

$highlight-font: 'Montserrat', sans-serif;
$main-font: $highlight-font;
$color--font: #8F54BA;

$ballsize: 20px;

body {
  margin: 0;
  padding: 0;
  font-family: $main-font;
  color: $color--font;
  line-height: 1.5em;
}

.section {
  padding: $padding;
  @media screen and (max-width: $tab - 1 ) {
    margin-bottom: $padding;
  }
}

.section--content {
  padding: $padding;
  @media screen and (max-width: $phone) {
    padding: 0;
  }
}

.label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.85em;
}

.column .label {
  margin-top: 0;
}

.header {
  background-color: $color--white;
  color: black;
}

.section--header {
  color: black;
  font-size: 18px;
  display: flex;

  @media screen and ( min-width: $tab) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.link i {
  margin-right: 10px;
}

.logo {
  padding-right: $padding;
  display: inline-block;
  text-align: right;
  margin-left: 16px;
  flex-grow: 1;

  img {
    max-width: 240px;
    height: auto;
  }
}

.faicon{
  color: #8F54BA;
}

a.headerlink{
  color: #07BBBC;
}

#address{
  color: #8F54BA;
}

div.user{
  padding-top: 2em;
}

h2.name{
  margin-top: 0;
}

div.nav__image {
  max-width: 180px;
}

@media screen and (min-width: 920px) {
  .logo--desktop {
    display: inline-block;
  }

  .logo--mobile {
    display: none;
  }
}

@media screen and (max-width: 919px) {
  .logo--desktop {
    display: none;
  }

  .logo--mobile {
    display: inline-block;
  }

  .logo img {
    max-width: 67px;
  }
}

.link {
  @media screen and (min-width: $tab +1) and (max-width: $laptop) {
    text-align: center;
    font-size: 0.9em;
  }
}

.link-container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $tab) {
    flex-direction: column;
  }

  @media screen and (min-width: $tab+1) {
    min-width: 35em;
  }
}

.button {
  transition: all 0.125s;
  background-color: $color--theme;
  padding: $padding;
  color: $color--white;
  display: inline-block;

  &:hover {
    transition: all 0.125s;
    color: $color--white;
    background-color: darken($color--theme, 10%);
  }
}

.button--inverted {
  background-color: $color--white;
  color: $color--theme;

  &.button--transparent:hover {
    color: darken($color--theme, 10%);
  }
}

.button--transparent {
  background-color: transparent;
  border: 1px solid $color--theme;

  &:hover {
    background-color: transparent;
    border: 1px solid darken($color--theme, 10%);
  }
}

a {
  color: $color--theme;
  text-decoration: none;

  &:hover {
    color: darken($color--theme, 10%);
  }
}

div[v-cloak] {
  display: none;
}

.content {
  & > h1 {
    margin-top: 0;
  }
}

.footer {
  min-height: 48px;
}

.header {
  border-bottom: 1px solid $color--border;
  height: 100px;
}

button,
select,
textarea,
input:not([type="submit"]) {
  min-height: 48px;
}

button {
  &:hover {
    cursor: pointer;
  }
}

input:not([type="submit"]) {
  padding: $padding $padding / 2;
}

button {
  box-shadow: none;
  border: none;
}

.footer {
  margin-top: $padding;
  border-top: 1px solid $color--border;
}


.section--breadcrumbs {
  font-size: 0.85em;
}

.section--breadcrumbs + .main-container {
  margin-top: 0;
}

.container {
  background-color: $color--background;
  border: 1px solid $color--border;
}

.constrained {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $tab) {
    max-width: $tab;
  }

  @media screen and (min-width: $laptop) {
    max-width: $laptop;
  }

  @media screen and (min-width: $laptop) {
    max-width: $laptop;
  }

  @media screen and (min-width: $desktop) {
    max-width: $desktop;
  }
}

.company {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.companies--table {
  display: table;
  width: 100%;
}

.company--table {
  display: table-row;
  width: 100%;

  & > div {
    display: table-cell;
  }
}

/** Custom CV styles **/

.box {
  background-color: $color--background;
  padding: $padding;

  .sidebar & {
    border-top: 1px solid $color--border;

    &--full {
      margin-bottom: 0;
    }
  }

  .box__content {
    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &--full {
    padding: 0;
    margin-bottom: $padding * 2;
    border-top: none !important;

    .box__title,
    .box__content {
      padding: $padding;
    }

    .box__title {
      border-bottom: 1px solid $color--border;

      h2 {
        margin-bottom: 0;
      }
    }
  }

}

.box--plain {
  background-color: $color--white;
  border: none;
}

%label,
.box__title {
  text-transform: uppercase;
  font-weight: bold;

  h2 {
    font-size: 1em;
    margin-top: 0;
    margin-bottom: $padding;
  }
}

.content {
  margin-top: $padding * 2;
}

@media screen and (min-width: $tab) {

  .user {
    display: flex;
  }

  .sidebar {
    width: 40%;
    margin-right: $padding * 2;
  }

  .content {
    margin-top: 0;
    width: 60%;
  }
}

@media screen and (min-width: $laptop) {
  .sidebar {
    width: 40%;
  }

  .content {
    width: 70%;
  }
}

.label {
  @extend %label;
}

.row {
  display: flex;

  .column {
    width: 50%;
    @media screen and (min-width: $tab) {
      &--big {
        width: 65%;
      }

      &--small {
        width: 35%;
      }
    }

  }
}

.skills {
  display: flex;
  width: 100%;
  align-items: center;
}

.language-list,
.skill-list {
  margin: 0;
  list-style: none;
  padding: 0;

  display: flex;
  flex-direction: column;
}

.language-list li {
  display: flex;
  justify-content: space-between;
  margin-bottom: $padding / 2;
  border-bottom: 1px solid $color--border;
  font-size: 0.9em;

  &:last-child {
    border-bottom: 0;
  }
}

.skill {
  display: flex;
  justify-content: space-between;
  margin-right: 20%;
  text-transform: uppercase;
  margin-bottom: 3px;

  @media screen and (max-width: $desktop) {
    margin-right: 10%;
  }
  @media screen and (max-width: $tab) {
    margin-right: 2%;
  }
}

.skill__labels {
  flex-direction: column;

  @media screen and (min-width: $tab - 200px) {
    flex-direction: row;
  }
}

.skill__years {
  font-size: 0.85em;
}

.skill__level {
  text-align: right;
  display: inline-block;
}

.skill__balls {
  height: 24px;
  justify-content: flex-end;
}

img {
  max-width: 100%;
  height: auto;
}

.headline {
  color: $color--font;
  h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1.8em;
    text-transform: uppercase;
  }

  h2 {
    margin-top: 0;
    font-size: 1em;
    font-weight: 300;
  }
}

.image {
  line-height: 0;
  text-align: center;

  img {
    width: 100%;
  }
}

.ball {
  width: $ballsize;
  height: $ballsize;
  border: 1px solid #07BBBC;
  border-radius: 50%;
  overflow:hidden;
  display:inline-block;
  margin: 0 1px;
}

.ballInner {
  background-color: #07BBBC;
  height: 100%;
}

.project {
  h2 {
    text-transform: uppercase;
    font-size: 1.2em;
  }

  &__tech {
    margin-top: $padding;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  padding-bottom: $padding;
  margin-bottom: $padding;
  border-bottom: 1px solid $color--border;

  &:last-child {
    border-bottom: 0;
  }
}

.course {
  h2 {
    text-transform: uppercase;
    font-size: 1.2em;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  padding-bottom: $padding;
  margin-bottom: $padding;
  border-bottom: 1px solid $color--border;

  &:last-child {
    border-bottom: 0;
  }
}

.navigation {
  @media screen and (max-width: $phone) {
    padding-right: 0;
    padding-left: 0;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-top: $padding;
  padding-top: $padding;
  padding-right: 3em;
  padding-left: 3em;
  
}

.nav__image img {
  max-width: 130px;
}

.navigation a {
  text-align: center;
  padding: 2em;
  width: 180px;
}

.navigation h2 {
  font-size: 16px;
}

.hidden {
  display:none;
}

//Print styles
@media print {

  .body {
    -webkit-print-color-adjust: exact !important;
    color: black !important;
    background-color: white;
  }

  div.box.box--full {
    color-adjust: exact;
    // page-break-inside: avoid;
  }

  .box__content.row:nth-child(2n){
    -webkit-print-color-adjust: exact !important;
    background-color: whitesmoke !important;
  }

  .footer, .navigation {
    display: none;
  }

  .image {
    width: 300px;
  }

  .logo--mobile {
    display: none;
  }

  .ballInner {
    -webkit-print-color-adjust: exact !important;
    background-color: 000000;
  }
}
